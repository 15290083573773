@import 'variables';

.accessibility {
  position: relative;
  width: 55px;
  height: 55px;
  text-align: right;

  &-header {
    border-bottom: 1px solid $lightest-text-color;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      padding: 0 0 0 10px;
    }

    &-times {
      svg {
        padding: 0;

        &:hover {
          cursor: pointer;
          color: $contrast-text-color;
        }
      }
    }
  }

  &-form {
    position: absolute;
    top: 0;
    left: 0;
    width: 330px;
    border-radius: $border-radius;
    top: 55px;
    background-color: $default-bg-color;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-alert {
    margin: 15px;
    padding: 10px;
    background-color: $lightest-text-color;
  }

  &-options {
    padding: 5px 0;
  }

  &-option {
    padding: 5px 10px;
    cursor: pointer;
    span {
      margin: 0 2px;
      padding: 0 3px;
    }
    .item {
      margin-right: 10px;
      transition: all .3s ease-in-out;
      color: $light-text-color;
      &:hover, &.active {
        color: $contrast-coloured-text;
        border-bottom: 2px solid $light-text-color;
      }
    }
  }

  &-icon {
    border-radius: $border-radius-round;
    padding: 15px;

    svg {
      font-size: 1.5rem;
      cursor: pointer;
    }

    &:hover {
      background-color: $hover-bg-color;
    }
  }

  &-button {
    margin: 15px;
    width: 100px;
    min-width: auto;
  }
}

body {
  &.grey {
    filter: grayscale(100%);
  }

  &.contrast {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(255,255,255,.5);
      pointer-events: none;
      z-index: 1000;
    }
  }

  &.invert {
    filter: invert(1);
  }
}

.font {
  &-16 {
    font-size: 16px;
  }

  &-18 {
    font-size: 18px;
  }

  &-20 {
    font-size: 20px;
  }

  &-22 {
    font-size: 22px;
  }
}

@media (max-width: $media-sm) {
  .accessibility {
    position: static;

    &-form {
      width: 90%;
      margin: 0 5vw;
    }
  }
}

@import 'variables';

.header {
  align-items: center;
  background-color: rgba($default-bg-color, 0.7);
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  height: 88px;
  padding: 0 40px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  backdrop-filter: blur(20px);
  z-index: 200;

  &-fields {
    display: flex;
    flex-direction: row;
  }

  &-logo {
    display: flex;
    height: 45px;
    align-items: center;
    cursor: pointer;
  }

  &-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    position: relative;
    order: 3;

    &-text {
      padding: 10px 20px;
      text-decoration: none;
      color: $main-text-color;

      &:hover {
        background-color: $hover-bg-color;
        border-radius: 9999px;
        cursor: pointer;
      }
    }

    &-dropdown {
      display: none;
      flex-direction: column;
      position: absolute;
      border: 1px solid $lightest-text-color;
      top: 60px;
      background-color: $default-bg-color;
      z-index: 10;

      a {
        padding: 10px 20px;
        text-decoration: none;
        color: $main-text-color;
        display: block;        

        &:hover {
          background-color: $hover-bg-color;
        }

        &:not(:last-child) {
          border-bottom: 1px solid $lightest-text-color;
        }
      }


      &.open {
        display: flex;
      }
    }

    svg {
      color: $light-text-color;
      padding: 0 5px;
      font-size: 12px;
    }
  }
  
  &-switch-theme {
    margin: auto auto auto 0;

    .header-cell-text {
      height: 28px;
      width: 28px;
      padding: 12px;
      font-size: 28px;

    }
  }
}

img.header-cell {
  cursor: pointer;
}

.hamburger {
  display: none;
  position: relative;

  #hamburger__toggle {
    opacity: 0;
  
    &:checked + .hamburger__btn > span {
      transform: rotate(45deg);
  
      &::before {
        top: 0;
        transform: rotate(0deg);
      }
  
      &::after {
        top: 0;
        transform: rotate(90deg);
      }
    }
    &:checked ~ .hamburger__box {
      left: 0 !important;
    }
  }
  .hamburger__btn {
    position: absolute;
    top: 7px;
    left: 0;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 1;
    
    span, span::before, span::after {
      display: block;
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #616161;
      transition-duration: .25s;
    }
  
    span::before {
      content: '';
      top: -8px;
    }
  
    span::after {
      content: '';
      top: 8px;
    }
  }
}

@media (max-width: $media-mobile) {
  .header {
    padding: 0 20px;
    height: 60px;
    justify-content: space-between;
    background-color: $default-bg-color;
    &-cell {
      padding: 0;
      flex-direction: column;

      &-dropdown {
        position: relative;
        width: 100%;
        top: 0;
        text-align: center;
      }
      &-text {
        width: 100%;
        text-align: center;
        &:hover {
          border-radius: 0;
        }
      }
    }
    &-switch-theme {
      margin-left: 35px;
    }
    &-logo {
      width: auto;
      height: 40px;
    }
    &-fields {
      display: none;

      &.open {
        display: flex;
        position: absolute;
        background-color: $default-bg-color;
        flex-direction: column;
        right: 0;
        top: 60px;
        left: 0;
      }
    }
  }

  .hamburger {
    display: inline;
    order: 5;
  }
}
@import 'variables';

///////// Select ///////////
.search-select .MuiOutlinedInput-notchedOutline {
  display: none;

  .MuiInputBase-root {
    color: $contrast-text-color !important;
  }
}

.MuiInputBase-root, .MuiTypography-root {
  font-family: inherit!important;
}

.MuiSelect-select {
  padding-right: 12px!important;
  color: $contrast-text-color !important;
}

.details-room-row {
  .MuiInputBase-root {
    color: $main-text-color !important;
    width: 100%;
    height: 34px;
    min-width: 100px;
    text-align: center;
  }
}

.MuiList-root {
  direction: rtl;
}

///////////////autocomlete/////////////////////
.MuiAutocomplete {
  &-popper {
    direction: rtl;
  }

  &-root {
    .MuiInputBase-root {
      padding: 8px 15px !important;
      outline: none;
    }

    .MuiButtonBase-root {
      margin: 3px 0 0 3px !important;
    }

    &.MuiAutocomplete-my-multiple .MuiInputBase-root {
      padding: 8px 5px !important;
    }

    .MuiChip-deleteIcon {
      margin: 0 -6px 0 5px !important; 
    }
  }

  &-endAdornment  {
    top: 6px !important;
    left: 0 ;
    right: auto!important;
  }

  &-clearIndicator {
    display: none !important;
  }

  &-my-dropdown {
    padding: 10px;
    border-bottom: 1px solid transparent;
    cursor: pointer;
    &:hover, &.selected {
      background-color: $hover-bg-color;
      border-color: $default-bg-color;
    }
    svg {
      border-color: $contrast-coloured-text;
      margin-left: 5px;
      width: 10px;
    }
  }
}

.MuiInputBase-root  {
  .MuiAutocomplete-input {
    padding: 0 !important;
    color: $contrast-text-color !important;
    max-width: calc(100% - 60px);
    color: $main-text-color !important;

  }

  .MuiOutlinedInput-notchedOutline {
    border-color: $light-text-color !important;
    border-width: 1px !important;
  }

  .MuiChip-root {
    background-color: $hover-bg-color !important;
    color: $main-text-color !important;
  }
}

.MuiSlider-root {
  color: $contrast-coloured-text!important;
}

.MuiAutocomplete-popper > div {
  background-color: $default-bg-color !important;
  color: $main-text-color !important;
}

.color {
  background-color: $contrast-coloured-text;
}

////////// skeleton ////////////
.MuiSkeleton-root {
  background-color: $lighter-text-color !important;
}

/////////// DatePicker ///////////
.react-date-picker {
  height: 100%;
  width: 100%;
  &__wrapper {
    border: none;
  }
  &-custom {
    padding: 0;;
  }
  &__inputGroup {
    direction: ltr;
    text-align: right;
    padding: 8px;
    &__input:invalid {
      background: none;
    }
  }

  &__button {

    &:hover{
      svg {
        stroke: $contrast-coloured-text!important;
      }
    }
    svg {
      stroke: $main-text-color;
    }
  }

  &-empty {
    .react-date-picker__inputGroup__divider {
      display: none;
    }
  }
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: $light-text-color !important;
}

.react-calendar {
  button {
    border-radius: $border-radius;
  }
  &__tile--now {
    background-color: $lightest-text-color;  
  }
  &__tile--active, &__tile--active:enabled:hover, &__tile--active:enabled:focus {
    background-color: $contrast-coloured-text;  
  }
}

.MuiAlert {
  &-icon {
    margin-right: 0;
    margin-left: 12px;
  }
  &-action {
    padding: 4px 16px 0 0!important;
  }
}

.react-calendar {
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
  border-radius: 4px;
  border: none;
  &__tile:disabled {
    background-color: transparent !important;
  }

  &__month-view__days__day--weekend:disabled {
    color: $light-text-color !important;
  }
}
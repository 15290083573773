@import 'variables';

.pay {
  &-wrapper {
    width: 470px;
    border: 1px solid #ccc;
    padding: 103px 30px 35px;
    border-radius: 10px;
    box-shadow: 0 0 10px #ccc;
    margin-top: 36px;
    position: absolute;
    align-items: center;
  }

  &-2 {
    display: flex;
    flex-direction: row;
    height: 38px;
    width: 100%;
    margin-bottom: 12px;
    justify-content: space-between;

  }

  &-21 {
    margin-bottom: 21px;
  }



  &-3 {
    height: 38px;
    width: 80%;
    margin-bottom: 12px;
  }
}



@media (max-width: 496px) {
  .pay {
    &-wrapper {
      padding: 70px 30px 35px;
      width: 96%;
    }
  }
}
@import 'variables';

.itinerary {
  display: flex;
  position: relative;
  padding: 5px 0;
  &-download {
    position: absolute;
    top: -100%;
  }

  
  &-list {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 1px;
      border-left: 1px solid $main-text-color;
      top: 15px;
      bottom: 15px;
      left: 31px;
      z-index: 0;
    }
  }
  &-item {
    padding: 5px 0;
    transition: all .4 linear;
    position: relative;
    padding: 10px 15px 10px 50px;
    list-style-type: none;
    cursor: pointer;
    display: flex;

    &:hover, &.active {
      color: $contrast-coloured-text;
      &:before {
        transition: all .4 linear;
        border-color: $contrast-coloured-text;
      }
      &:after {
        content: '';
        transition: all .4 linear;
      }
    }
    &:before {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 2px solid $main-text-color;
      top: 14px;
      left: 24px;
      background-color: $default-bg-color;
      z-index: 1;
    }
    &:after {
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $main-text-color;
      top: 18px;
      left: 28px;
      z-index: 2;
    }
    .day {
      flex: 0 0 44px;
      display: inline-block;
    }
    .item-icon {
      flex: 0 0 22px;
    }
    .item-location {
      svg {
        width: 8px;
      }
    }
  }


  &-list {
    flex: 0 0 30%
  }
  &-content {
    position: relative;
    flex: 0 0 70%;
    max-height: 0;
    overflow-y: auto;
    min-height: 300px;

    img {
      float: right;
      border-radius: $border-radius;
      margin-left: 10px;
      width: 200px;
    }
  }

  &-text {
    margin: 0 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 15px;
  }

  &-desc {
    margin-top: 10px;
    &:first-child {
      padding-top: 10px;
      border-top: 1px solid $lighter-text-color;
      display: block;
    }
  }
}

.include-wr {
  padding: 10px 15px;
  .title {
    border-bottom: 1px solid $lightest-text-color;
    font-weight: 500;
    display: block;
    padding: 0 0 10px;
  }

  .item {
    display: flex;
    padding: 5px 0;
  }

  svg {
    margin-left: 7px;
  }
  .include {
    svg {
      color: green
    }
  }
  .no-include {
    svg {
      color: red
    }
  }
  .info {
    svg {
      color: #0b9be5;
    }
  }
}

@media (max-width: $media-mobile) {
  .itinerary {
    flex-direction: column;
    &-list {
      flex: 0 0 100%;
    }

    &-content {
      flex: 0 0 100%;
    }
  }
}
@import 'variables';

.footer {
  padding: 25px 80px 15px;
  border-top: 1px solid $light-text-color;
  line-height: 2.2;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &-wrapper {
    margin-top: auto;

  }

  &-col {
    flex: 0 0 25%;

    &-main {
      display: flex;
      flex: 0 0 50%;
      
      justify-content: space-between;
    }

    &-bottom {
      flex: 0 0 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $lightest-text-color;
      width: 100vw;
      flex-direction: column;
      padding: 15px 0;
      border-top: 1px solid $light-text-color;

      a {
        padding: 0 10px;
        text-decoration: none;
        color: $main-text-color;

        &:not(:first-child) {
          &::before {
            content: '|';
            padding-left: 19px;
          }
        }
      }

      .be-pro-title {
        color: $contrast-coloured-text;
        font-weight: bold;
      }
      &-row {
        margin-bottom: 7px;
      }
    }

    a {
      text-decoration: none;
      color: $main-text-color;
      display: flex;
      align-items: center;
    }
  }

  img {
    height: 40px;
  }

  svg {
    margin-left: 5px;
  }

  .text-bold {
    color: $contrast-text-color;
  }
}

@media (max-width: $media-mobile) {
  .footer {
    padding: 50px 20px 0;
    flex-wrap: wrap;

    &-col {
      flex: 0 0 50%;
      padding-right: 0;
      text-align: center;
      margin-top: 20px;
      
      &-bottom {
        a {
          padding: 0 3px;
          max-width: 80px;
          text-align: center;

          &:not(:first-child) {
            &::before {
              content: '';
            }
          }
        }
      }

      
    }
  }
}
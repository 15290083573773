@import 'variables';

.sales {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
  &-title {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 10px;
  }  
  &-cards {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }

  &-card-wr {
    padding: 10px;
    width: 25%;
  }
  &-card {
    border-radius: $border-radius;
    border: 1px solid $lightest-text-color;
    position: relative;
    .image {
      height: 180px;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        background-color: rgba(0,0,0,0.1);
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        border-radius: $border-radius;
      }
      img {
        width: 100%;
        height: 180px;
        border-radius: $border-radius $border-radius 0 0;; 
      }
    }

    .hotel-name {
      font-size: 1.13rem;
      font-weight: bold;
      padding: 0 10px;
      line-height: 1.2;
      bottom: 10px;
      color: #fff;
      text-shadow: 2px 2px black;
      position: absolute;
      z-index: 2;
    }

    &-items {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      padding: 10px 10px 0;
      .item {
        display: flex;
        margin-left: 20px;
        font-size: 15px;
        color: $light-text-color-dark;
        align-items: center;

        svg {
          color: $light-text-color-dark;
          margin-left: 7px;
          height: 12px; 
        }
      }
    }
    &-gallery {
      color: #fff;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 2;
      cursor: pointer;
      padding: 9px;
      margin: -9px -6px;
      background-color: rgba(0,0,0,0.5);
      border-radius: $mobile-border-radius;

      &:hover {
        opacity: 0.7;
      }
      svg {
        color: #fff;
        margin-left: 7px;
        height: 12px; 
      }

    }
    &-footer {
      display: flex;
      padding: 10px 10px 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .person-price {
        line-height: 1;
        .title {
          font-size: 0.88rem;
          color: $light-text-color-dark;
        }
        .price {
          font-size: 1.37rem;
          font-weight: 500;
          font-weight: bold;
          color: $contrast-coloured-text;
        }
      }
      .total-price {
        margin-bottom: 7px;
        .title {
          font-size: 0.88rem;
          color: $light-text-color-dark;
        }
        .price {
          font-size: 1rem;
          color: $contrast-coloured-text;
        }
      }

      button {
        width: 100%;
      }
    }
  }
}

@media (max-width: $media-laptop-sm) {
  .sales {
    padding: 20px 15px;
    &-card-wr {
      width: 33.333%;
    }
  }
}

@media (max-width: $media-tablet) {
  .sales {
    &-card-wr {
      width: 50%;
    }
  }
}

@media (max-width: $media-mobile) {
  .sales {
    &-card-wr {
      width: 100%;
    }
  }
}
@import 'variables';

.filter {
  position: relative;
  padding: 20px;
  border: 1px solid $lightest-text-color;
  border-radius: 16px;
  width: 100%;

  &-reset {
    position: absolute;
    left: 20px;
    color: $danger-color;
    text-decoration: underline;
    cursor: pointer;
    z-index: 10;
    font-size: 0.88rem;
    top: 23px;

    &-wr {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &-item {
    margin-bottom: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
  
    &:not(:first-child):after {
      content: '';
      position: absolute;
      top: -10px;
      right: 10px;
      left: 10px;
      border-bottom: 1px solid $lightest-text-color;
    }
    &-title {
      font-weight: bold;
      margin-bottom: 7px;
    }
    &-content {
      display: flex;
      flex-direction: column;
      font-size: 0.88rem;
      margin-bottom: 7px;

      .with-price {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
      }

      .full {
        flex: 0 0 100%;
      }
    }
  }
}
@import 'variables';

.main {
  &-carousel-wr {
    position: relative;
    max-height: 1000px;
    min-height: 400px;
    width: 100%;
  }

  &-image-wr {
    width: 100%;
    min-height: 400px;
    max-height: 1000px;
  }
  
  &-carousel-short {
    min-height: auto;
    height: 150px;

    .carousel {
      height: 150px;
      overflow: hidden;
    }
  }

  &-title {
    font-size: 1.3rem;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 500;
    color: $contrast-coloured-text;
  }

  &-text {
    display: flex;
    flex-direction: column;
    padding: 40px 40px;
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;

    p {
      margin-bottom: 20px;

      span {
        color: $main-text-color!important;
      }
    }
  }
}

.banners {
  padding: 40px 20px;
  max-width: 1200px;
  width: 100%;
  margin: auto;

  .title {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &-images {
    text-align: right;
  }
  img {
    padding: 15px 0 15px 30px;
    text-align: right;
  }
}

@media (max-width: $media-mobile) {
  .main {
    &-carousel-wr {
      height: 500px;

      .carousel {
        overflow: hidden;

      }
    }
    &-image-wr {
      height: 500px;
      width: auto;
    }
    &-carousel-short {
      height: 350px;
    }

    &-text {
      padding: 60px 10px;
    }
  }

  .banners {
    padding: 15px;

    &-images {
      text-align: center;

      img {
        max-width: 100%;
      }
    }
  }
}
@import 'variables';

.single-sale {
  display: flex;
  justify-content: center;
  text-align: center;

  .sales-card-wr {
    min-width: 500px;

    .image {
      height: 240px;

      img {
        height: 100%;
      }
    }

    .sales-card {
      &-items {
        margin: 16px 0 20px;
        line-height: 1.5;
        align-items: center;
      }
      &-footer {
        width: 200px;
        margin: auto;
      }
    }
  }
}

@media (max-width: $media-tablet) {
  .single-sale {
    margin-top: 40px;
    .sales-card-wr {
      min-width: auto;
    }
  }
}
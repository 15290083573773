
$main-text-color-light: #6B7280;
$main-text-color-dark: #d1d5db;
$main-text-color: var(--main-text-color);

$contrast-text-color-light: #000;
$contrast-text-color-dark: #fff;
$contrast-text-color: var(--contrast-text-color);

$light-text-color-light: #9CA3AF;
$light-text-color-dark: #6B7280;
$light-text-color: var(--light-text-color);

$lighter-text-color-light: #e5e6e8;
$lighter-text-color-dark: #344051;
$lighter-text-color: var(--lighter-text-color);

$lightest-text-color-light: #F3F4F6;
$lightest-text-color-dark: #1F2937;
$lightest-text-color: var(--lightest-text-color);

$contrast-coloured-text-light: #003654;
$contrast-coloured-text-dark: #5890ae;
$contrast-coloured-text: var(--contrast-coloured-text);

$hover-bg-color-light: #F3F4F6;
$hover-bg-color-dark: #1f2937;
$hover-bg-color: var(--hover-bg-color);

$default-bg-color-light: 255, 255, 255;
$default-bg-color-dark: 17, 24, 39;
$default-bg-color: var(--default-bg-color);

$danger-color: #EF4444;
$success-color: #5cb85c;
$warning-color: #f0ad4e;

$default-font-size: 1rem;
$border-radius: 16px;
$border-radius-round: 9999px;
$mobile-border-radius: 6px;

$media-sm: 576px;
$media-mobile: 768px;
$media-tablet: 992px;
$media-laptop-sm: 1200px;

body.dark {
  --main-text-color: #{$main-text-color-dark};
  --light-text-color: #{$light-text-color-dark};
  --lightest-text-color: #{$lightest-text-color-dark};
  --lighter-text-color: #{$lighter-text-color-dark};
  --contrast-text-color: #{$contrast-text-color-dark};
  --default-bg-color: rgb(#{$default-bg-color-dark});
  --hover-bg-color: #{$hover-bg-color-dark};
  --contrast-coloured-text: #{$contrast-coloured-text-dark};
}

body {
  --main-text-color: #{$main-text-color-light};
  --light-text-color: #{$light-text-color-light};
  --lightest-text-color: #{$lightest-text-color-light};
  --lighter-text-color: #{$lighter-text-color-light};
  --contrast-text-color: #{$contrast-text-color-light};
  --default-bg-color: rgb(#{$default-bg-color-light});
  --hover-bg-color: #{$hover-bg-color-light};
  --contrast-coloured-text: #{$contrast-coloured-text-light};
}
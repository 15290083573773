@import 'variables';

.search {
  &-wrapper {
    width: 80%;
    position: absolute;
    top: 40px;
    z-index: 100;
    left: 50%;
    transform: translateX(-50%);

    &-main {
      top: 80px;
    }
  }

  &-head {
    display: flex;
    margin-bottom: 20px;
    font-size: 1.13rem;
    color: $contrast-text-color;

    &-item {
      margin-left: 10px;
      border-radius: 15px;
      padding: 5px 30px;
      background-color: rgba(255, 255, 255, .6);
      cursor: pointer;
      position: relative;
      &:hover, &.active {
        opacity: .9;
        background-color: $default-bg-color;
      }

      &.active {
        &::before {
          content: '·';
          font-size: 100px;
          position: absolute;
          top: -38px;
          right: 4px;
        }
      }
    }
  }
  &-main {
    background-color: $default-bg-color;
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    height: 80px;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1);
    border-radius: $border-radius-round;
    margin: auto;

    &-cell {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding-right: 40px;
      height: 100%;
      width: 50%;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 20px;
        bottom: 20px;
        border-left: 1px solid $light-text-color;
      }
      &-last:before {
        display: none;
      }
    }

    &-box {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      margin-left: 8px;
      z-index: 2;

      .react-date-picker__calendar {
        top: 70px !important;
      }

      &.react-date-picker-empty {
        .react-date-picker__inputGroup {
          &::before {
            content: 'את כל';
          }
        }
      }

      .react-date-picker__inputGroup {
        padding: 0 12px;
        color: $contrast-text-color;
        font-weight: 600;
        height: 23px;
      }
    }

    &-text {
      color: $light-text-color;
      font-size: 0.88rem;
      margin-top: 5px;
    }

    &-location {
      width: 50%;
    }

    &-button {
      background-color: $contrast-coloured-text;
      display: flex;
      flex: 0 0 60px;
      align-items: center;
      justify-content: center;
      margin: auto 0 auto auto;
      height: 60px;
      border-radius: 9999px;
      margin-left: 12px;
      z-index: 2;
      cursor: pointer;

      svg {
        color: $default-bg-color;
        font-size: 28px;
      }
    }
  }

  &-icon {
    color: $light-text-color;
    font-size: 28px;
    padding-left: 15px;
    position: relative;
    z-index: 2;
  }
  &-input {
    background-color: $default-bg-color;
    color: $contrast-text-color;
    border: none;
    outline: none;
    font-size: 1rem;
    font-weight: 600;
    padding: 0;

    &:focus::placeholder {
      color: $light-text-color;
    }
    &::placeholder {
      color: $contrast-text-color;
    }
  }
  &-bold-text {
    border: none;
    outline: none;
  }
}

.search-main-cell-active {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 2px;
    bottom: 0;
    border-radius: 999px;
    background: $hover-bg-color;
    z-index: 0
  }
  
  &-last:after {
    left: -72px;
  }
}

@media (max-width: $media-mobile) {
  .search-main {
    flex-direction: column;
    border-radius: $border-radius;
    padding: 30px 15px;
    height: auto;
    background-color: rgba(255, 255, 255, .6);
  }
  .search-wrapper {
    width: 96%;
  }

  .search-main-cell {
    width: 100%;
    padding: 8px 15px;
    border: 1px solid $light-text-color;
    background-color: $default-bg-color;
    border-radius: $border-radius;
    margin-bottom: 10px;

    &::before {
      display: none;
    }

    .active {
      border-radius: $border-radius;
    }
  }

  .search-main-button {
    flex: 0 0 100%;
    width: 100%;
    padding: 15px;
    height: 70px;
    border-radius: $border-radius;
  }

  .search-main-cell-active {
    &:after {
      border-radius: $border-radius;

    }
    &-last:after {
      left: 0;
    }
  }
}
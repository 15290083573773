@import 'variables';

.book {
  &-wrapper {
    .details {
      &-sidebar {
        flex: 0 0 370px;
      }

      &-center {
        flex: 0 1 calc(100% - 350px);
      }
    }
  }

  &-passenger {
    padding: 10px 10px 30px;

    &-header {
      padding: 10px 15px 10px 23px;
      background-color: $lightest-text-color;
      margin: 0px -10px 20px;
      width: calc(100% + 20px);
    }

    &-price {
      padding: 10px 15px;

      .text-bold {
        font-size: 1.25rem;
      }
    }

    &-submit {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &-pax {
      &:not(:last-child) {
        border-bottom: 1px solid $lightest-text-color;
        margin-bottom: 40px;
      }
      .MuiInputBase-root {
        color: var(--main-text-color) !important;
        height: 34px;
        min-width: 100px;
        text-align: right;
        width: 100%;
        font-size: 0.8rem;

        .MuiOutlinedInput-notchedOutline {
          border: 1px solid var(--lighter-text-color) !important;
        }
      }

      width: 100%;
    }

    &-visa {
      width: 100%;
      border: 1px solid $lightest-text-color;
      margin: 0 15px 30px;

      &-header {
        font-weight: 600;
        margin-bottom: 10px;
        padding: 10px 15px;
        width: 100%;
        background-color: $lightest-text-color;
      }
    }

    &-suppliment {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;

      .ksml {
        padding-right: 30px;
        margin-top: 25px;
      }
      .MuiFormGroup-root {
        margin-top: 6px;
      }
      .MuiFormControlLabel-root {
        height: 28px;
        margin-right: 10px;
      }
    }

    &-suppliments {
      margin-bottom: 30px;
      flex: 0 0 100%;
    }
  }

  &-success {
    &-wrapper {
      text-align: center;
      margin-top: 20px;
      margin-bottom: 50px;

      a {
        padding: 0 5px;
        color: $main-text-color;
      }
    }

    &-title {
      font-size: 1.25rem;
      font-weight: 600;
      margin-bottom: 20px;
    }

    &-subtitle {
      font-size: 1.25rem;
    }

    &-details {
      max-width: 800px;
      margin: auto;
    }
  }
}

.rooms-table {
  display: flex;
  flex-direction: column;

  &-header {
    display: flex;
    flex: 0 0 100%;
    flex-direction: row;
    background-color: $lightest-text-color;
    font-weight: 600;
  }

  &-row {
    display: flex;
    flex: 0 0 100%;
    flex-direction: row;
    border-bottom: 1px solid $lightest-text-color;

    &:hover {
      background-color: $hover-bg-color;
    }
    .rooms-table-cell {
      border-right: 1px solid $lightest-text-color;

      &:first-child {
        border-left: 1px solid $lightest-text-color;
      }
    }
  }

  &-cell {
    padding: 10px 5px;

    &:first-child {
      padding-left: 15px;
      flex: 0 0 35%;
    }

    &:nth-child(2) {
      flex: 0 0 40%;
    }

    &:last-child {
      flex: 0 0 25%;
    }
  }
}

.success-ticket-ok {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 0;
}

.success-total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 0;
  font-size: 1.3rem;

  .texts {
    margin: auto;
  }
}

.ok {
  background-color: $success-color;
  padding: 10px 20px;
  border-radius: $mobile-border-radius;
  color: #fff;
}

.err {
  background-color: $danger-color;
  padding: 10px 20px;
  border-radius: $mobile-border-radius;
  color: #fff;
}

.warning {
  background-color: $warning-color;
  padding: 10px 20px;
  border-radius: $mobile-border-radius;
  color: #fff;
}

@media (min-width: 768px) {
  .col-md-3 {
    flex: 0 0 25%;
  }
}

.book-payment {
  margin-top: 20px;
  margin-bottom: -20px;
  padding: 20px 20px;
  border: 1px solid $lightest-text-color;
  border-radius: $border-radius;

  .row {
    margin: 0 -15px;
  }
  .MuiInputBase-root {
    width: 100%;
    height: 38px;

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid $lightest-text-color !important;
    }
  }

  .MuiSelect-select {
    padding-right: 32px !important;
    max-width: 290px;
  }

  .title {
    font-size: 1.13rem;
    font-weight: 600;
    margin-bottom: 20px;

    svg {
      margin-left: 6px;
    }
  }
}

@media (max-width: $media-mobile) {
  .book-passenger {
    padding: 10px;

    &-submit {
      display: flex;
      flex-direction: column;
      button {
        width: 100%;
        margin: 0 0 10px 0;
      }
    }
  }
}

@media (max-width: 450px) {
  .book-passenger {
    .inline-checkbox {
      flex-direction: column;
    }
  }
}

@import 'variables';

.ticket {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  &-header {
    display: flex;
    width: 100%;
    padding: 10px 15px;
    border-bottom: 1px solid $lightest-text-color;
    margin-bottom: 10px;

    svg {
      margin-left: 10px;
      width: 20px;
    }
  }
  &-logo {
    flex: 0 0 167px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    margin-right: 20px;

    &-img {
      width: 100%;
      height: 90px;
      max-width: 120px;
      margin-bottom: 5px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .name {
      font-size: 13px;
      color: $light-text-color
    }
  }
  &-route {
    display: flex;
    flex-direction: column;
    color: $main-text-color;
    align-items: center;
    flex: 0 0 30%;
    padding: 10px 0 25px;
    .city {
      font-size: 15px;
      margin-bottom: 5px;
      .code {
        font-size: 13px;
      }
    }
    .time {
      font-size: 1.25rem;
      font-weight: 500;
    }
    .date {
      font-size: 13px;
    }
  }
  &-separator {
    flex: 0 0 20%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    color: $light-text-color;
    flex-direction: row-reverse;
    &:after {
      content: '.';
      border-top: 1px dashed $light-text-color;
      left: 0;
      right: 0;
      top: 50%;
      position: absolute;
    }
    .circle {
      border-radius: 50%;
      height: 6px;
      width: 6px;
      background-color: $light-text-color;
    }

    .type-flight {
      font-size: 0.88rem;
      margin-bottom: 5px;
      text-align: center;
      top: 24%;
      left: 0;
      right: 0;
      position: absolute;
    }

    svg {
      transform: rotate(180deg);
    }
  }

  &-success {
    .ticket-route {
      flex: 0 0 175px;
    }

    .ticket-ok {
      display: flex;
      align-items: flex-end;
    }
  }
}

.ticket-sm {
  display: flex;
  flex-direction: row;
  padding: 0 15px;
  border-bottom: 1px solid $lightest-text-color;

  &:first-child {
    border-bottom: 1px solid $lightest-text-color;
  }
  &:hover {
    background-color: $hover-bg-color;
  }

  &-header {
    padding: 5px 15px;
  }

  &-logo {
    flex: 0 0 32%;
    text-align: center;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 100%;
      padding-bottom: 5px;
      height: auto;
      max-width: 80px;
    }
  }

  &-cell {
    display: flex;
    font-size: 0.88rem;
    border-right: 1px solid $lightest-text-color;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    &.date {
      flex: 0 0 22%;
    }

    &.short {
      flex: 0 0 13%;
    }
  }
}

@media (max-width: $media-tablet) {
  .ticket-logo {
    flex: 0 0 120px;
  }
}

@media (max-width: $media-mobile) {
  .ticket {
    margin-bottom: 0;
    &-header {
      margin-bottom: 0;
    }
    &-logo {
      flex: 0 0 100%;

      img {
        height: 80px;
        width: auto;
      }
    }
    &-route {
      flex: 0 0 30%;
      text-align: center;
      padding-bottom: 10px;
    }

    &-separator {
      flex: 0 0 40%;
    }

    &-success {
      .ticket-separator {
        display: none;
      }
      .ticket-ok {
        flex: 0 0 100%;
        justify-content: center;
        margin-bottom: 10px;
      }
    }
  }

}
@import 'variables';

.results {
  &-header {
    margin: 40px 60px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &-title {
    flex: 0 1 350px;
    h2 {
      font-size: 2.2rem;
      font-weight: 500;
      color: $contrast-text-color;
      margin-bottom: 5px;
    }
  }

  &-sorting {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 400px);
    &-item {
      flex: 10 0 33%;
      font-size: 1.25rem;
      border: 1px solid $lightest-text-color;
      display: flex;

      .content {
        display: flex;
        flex: 0 0 100%;
        padding: 10px 20px;
        align-items: center;

        .arrow {
          margin-right: auto;
        }

      }

      &:not(:last-child) {
        border-left: none;
      }

      &:first-child {
        border-radius: 0 16px 16px 0;
      }
      
      &:last-child {
        border-radius: 16px 0 0 16px;
      }

      svg {
        margin-left: 10px;
        font-size: 1rem;
        }

      &:hover {
        cursor: pointer;
        box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1);
      }
    }

    &-filter.content, &-switcher.content {
      display: none;
    }
  }

  &-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 60px 40px;
  }

  &-filter {
    width: 350px;


    button {
      display: none;
    }
  }

  &-list {
    width: calc(100% - 400px);

    .table-header {
      display: none;
    }
  }

  &-card {
    position: relative;
    border: 1px solid $lightest-text-color;
    border-radius: 16px;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    min-height: 200px;
    &:hover {
      cursor: pointer;
      box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1);
    }
    &-img {
      flex: 0 0 35%;
      padding-left: 15px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 0 16px 16px 0;
      }
    }
    &-content {
      display: flex;
      flex-direction: column;
      flex: 0 0 40%;
      padding: 15px 10px 15px 15px;
    }
    &-title {
      font-size: 1.3rem;
      font-weight: bold;
      margin-bottom: 7px;
      line-height: 1.2;
    }

    &-facilities {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      &-item {
        display: flex;
        margin-left: 20px;
        font-size: 1rem;
        color: $light-text-color-dark;
        align-items: center;
        margin-bottom: 5px;
        svg {
          color: $light-text-color-dark;
          margin-left: 7px;
          height: 12px; 
        }
      }
    }
    &-rating {
      margin-bottom: auto;
    }
    &-footer {
      flex: 0 0 25%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      line-height: 1;
      position: relative;
      padding: 15px 10px 15px 15px;

      .button-wr {
        width: 200px;
      }
    }

    &-order {
      display: flex;      
      align-items: flex-end;
      justify-content: flex-end;
      flex-direction: column;
    }
    &-price {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-bottom: 10px;
      .title {
        font-size: 0.88rem;
        color: $light-text-color-dark;
      }

      .price {
        font-size: 1.37rem;
        font-weight: 500;
        font-weight: bold;
        margin-bottom: 3px;
        color: $contrast-coloured-text;
      }

      .title-sm {
        font-size: 0.88rem;
        color: $light-text-color-dark;
      }

      .price-sm {
        font-size: 1rem;
        color: $contrast-coloured-text;
      }
    }
  }
}

.desktop {
  display: block !important;
}

.mobile {
  display: none !important;
}

@media (min-width: $media-laptop-sm) {
  .results-sorting-switcher.content {
    display: flex;
  }
  .results-list.short {
    margin-bottom: 50px;

    .table-header {
      flex-direction: row;
      display: flex;
      height: 50px;
      background-color: $lightest-text-color;
      font-weight: 600;
      align-items: center;

      .content {
        display: flex;
        flex-direction: row;
        flex: 0 0 62%;
        padding: 0 10px;

        .title {
          flex: 0 0 35%;
          margin-left: 10px;
        }

        .facilities {
          display: flex;

          .facility {
            &:nth-child(1) {
              flex: 0 0 115px;
            }
    
            &:nth-child(2) {
              flex: 0 0 123px;
            }
    
            &:nth-child(3) {
              flex: 0 0 92px;
            }
            
          }
        }
      }

      .table-footer {
        flex: 0 0 30%;
        display: flex;

        &-price {
          &:first-child {
            flex: 0 0 55%;
          }
  
          &:last-child {
            flex: 0 0 45%;
          }
        }
      }
    }

    .results-card {
      height: auto;
      min-height: 60px;
      margin-bottom: 0;
      border-radius: 0;
      &:not(:last-child) {
        border-bottom: 0;
      }
      
      &-img {
        display: none;
      }

      &-content {
        flex-direction: row;
        flex: 0 0 62%;
        padding: 0 10px;
        align-items: center;
        overflow: hidden;

      }

      &-title {
        font-size: 1rem;
        line-height: inherit;
        margin: 0 0 0 10px;
        display: flex;
        flex: 0 0 35%;
        font-weight: 400;
        display: flex;
        flex-direction: column;

        .MuiRating-root {
          font-size: 1.2rem;
        }
      }

      &-facilities {
        flex: 0 0 calc(330px);
        flex-direction: row;

        &-item {
          font-size: 0.88rem;
          margin: 5px 0;
          padding-left: 10px;

          &:nth-child(1) {
            flex: 0 0 115px;
          }
  
          &:nth-child(2) {
            flex: 0 0 123px;
          }
  
          &:nth-child(3) {
            flex: 0 0 92px;
          }
        }
      }

      &-price {
        margin-bottom: 0;
        display: flex;
        flex-direction: row;
        width: 100%;

        .price {
          font-size: 1rem;
          margin-bottom: 0;
          flex: 0 0 54%;
        }
        .title {
          display: none;
        }

        .price-sm {
          .title-sm {
            display: none;
          }
          flex: 0 0 46%;
        }
      }

      &-footer {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex: 0 0 38%;
        padding: 0 0 0 10px;

        .button-wr {
          order: 2;
          width: 62px;

          button {
            min-width: auto;
          }
        }

      }
    }
  }
}

@media (max-width: $media-laptop-sm) {

  .mobile {
    display: block !important;
  }
  
  .desktop {
    display: none !important;
  }
  .results {
    &-header {
      margin: 30px 20px;
    }
    &-main {
      margin: 0 20px;
    }

    &-list {
      width: 100%;
    }
    &-sorting {
      margin-top: 10px;
      width: 100%;

      &-filter.content {
        display: flex;
      }
    }
    &-card-footer {
      .button-wr {
        width: 62px;
        button {
          min-width: auto;
        }
      }
    }
    &-card-ski {
      flex-direction: column;
    }

    &-filter {
      position: absolute;
      background: $default-bg-color;
      width: 400px;
      display: none;

      &.open {
        display: block;
      }
    }
  }

  .sales-card-gallery {
    top: 20px !important;
    right: 20px !important;
  }
}

@media (max-width: $media-mobile) {
  .results {
    &-header {
      margin: 15px;
      flex-wrap: wrap;

      h2 {
        font-size: 1.3rem;
        margin-bottom: 0;
      }
    }

    &-title {
      margin-bottom: 10px;
    }

    &-main {
      margin: 0 15px;
      flex-direction: column;
    }

    &-card {
      min-height: 150px;
      height: auto;
      flex-wrap: wrap;

      &-rating {
        z-index: 1;
        position: relative;
        margin: 15px 0 0 -6px;

        > span {
          padding: 5px 10px;
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: $mobile-border-radius;

        }
      }


      &-title {
        font-size: 1.13rem;
        overflow: hidden;
        margin-bottom: 0;
        padding:  0 0 10px;

        .results-card-ski & {
          text-align: left;
          padding: 10px 20px 0;

        }
      }

      &-img {
        flex: 0 0 45%;
        padding: 10px;
        height: 170px;

        position: relative;

        .results-card-ski & {
          margin-top: -45px;
          flex: 0 0 250px;
          overflow: hidden;
        }

        img {
          height: 100%;
          border-radius: $border-radius;
        }
      }

      &-content {
        flex: 0 0 55%;
        padding: 10px;
      }

      &-footer {
        flex: 0 0 100%;
        .button-wr {
          width: 100%;
          button {
            width: 100%;
          }
        }
      }

      &-facilities {
        flex-direction: row;
        &-item {

          .results-card-ski & {
            flex: 0 0 50%;

          }
          margin-left: 0;
          padding-left: 20px;

          &.long {
            flex: 0 0 100%;
          }
        }
      }
    }

    &-filter {
      position: static;
      width: 100%;
      margin-bottom: 15px;
    }
  }
}


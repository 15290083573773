@import 'variables';

.app {
  color: $main-text-color;
  background-color: $default-bg-color;
  width: 100%;
  min-height: (calc(100vh + 100px));
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  direction: rtl;
}

.text-bold {
  font-weight: 600;
}

.text-italic {
  font-style: italic;
}

.text-default {
  font-size: $default-font-size;
}

.text-center {
  text-align: center;
}

.text-default-color {
  color: $main-text-color;
}

.danger-color {
  color: $danger-color;

  .text-default-color {
    color: $danger-color;

  }
}

.display-flex {
  display: flex;
  flex-direction: column;
}

.ml-4 {
  margin-left: 4px;
}

.mr-4 {
  margin-right: 4px;
}

.mr-20 {
  margin-right: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.w100p {
  width: 100%;
}

.mw-75 {
  min-width: 75px;
}

.about {
  padding: 60px 15px;
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;

  img {
    margin-top: 20px;
  }
}

.contact {
  &-wrapper {
    max-width: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 40px auto 80px;
    flex-direction: column;
    padding: 0 15px;

    a {
      text-decoration: none;
      color: $main-text-color;

      svg {
        margin: 0 10px 0 3px;
      }
    }
  }
}

.form {
  &-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &-group {
    width: 100%;
    margin-bottom: 20px;

    label {
      display: block;
      margin-bottom: 5px;
    }
  }

  &-control {
    width: 100%;
    padding: 8px;
    border: 1px solid $lighter-text-color;
    border-radius: 6px;
    height: 38px;
  }
  &-invalid-text {
    position: absolute;
    color: $danger-color;
    font-size: 0.88rem;
    margin-top: 2px;
  }
}

.col {
  &-12 {
    flex: 0 0 100%;
    padding: 0 15px;
  }

  &-md {
    &-6 {
      flex: 0 0 50%;
    }
  }
}

.button {
  &-st {
    min-width: 200px;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    color: $contrast-text-color;
    cursor: pointer;
  }

  &-colored {
    background-color: $contrast-coloured-text;
    color: #fff;
    &:hover {
      color: #ddd;
    }
  }

  &-disabled {
    color: #fff!important;
    opacity: 0.8;
    cursor: not-allowed!important;
  }
}

.not-found {
  margin: 80px auto;
  font-size: 1.25rem;
}

.mobile-diplay {
  display: none;
}

.flex-one {
  flex: 1;
}


.checkbox-wr {
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkmark {
      background-color: $hover-bg-color;
      &:after {
        display: block
      }
    }
  }
  .checkmark {
    width: 20px;
    flex: 0 0 20px;
    height: 20px;
    border-radius: 3px;
    margin-left: 8px;
    border: 1px solid $light-text-color;
    outline: none;
    cursor: pointer;
    display: block;
    &:after {
      content: '';
      display: none;
      right: 7px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: solid $contrast-coloured-text;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      position: relative;
   }
  }
}

.inline-link {
  text-decoration: underline;
  cursor: pointer;
}

.inline-checkbox {
  display: flex;

  .checkbox-wr {
    display: inline-flex;
  }
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.page {
  &-wrapper {
    flex: 0 0 100%;
    min-height: 600px;
    margin-top: 40px;
    flex-direction: column;
    padding-bottom: 60px;
  }

  &-error {
    position: absolute;
    bottom: 30px;
  }

  &-iframe {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 600px;
    z-index: 1;
  }
}

.form {
  &-group {
    margin-bottom: 25px;

    input {
      background-color: $default-bg-color;
      color: $main-text-color;
    }

    .invalid-feedback {
      display: block;
      position: absolute;
      &:focus {
        box-shadow: none!important;
      }
    }
  }

  &-control {
    &:focus, &:active, &:focus-visible {
      box-shadow: none!important;
      border: 1px solid $light-text-color!important;
      outline: none;
    }

    &.textarea {
      height: 150px;
      resize: none;
    }

    &.is-invalid {
      border-color: $danger-color!important;
      &:focus {
        border-color: $danger-color!important;
      }
    }
  }
}

.modal {
  &-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  &-dialog-st {
    width: 50%;
    max-width: 1000px;
    height: 90%;
    background-color: $default-bg-color;
  }

  &-text-st {
    padding: 15px;
    border-top: 1px solid $lightest-text-color;
    overflow-y: auto;
    max-height: calc(100% - 60px);
  }

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    color: $main-text-color;
    .name {
      font-size: 1.37rem;
      font-weight: 500;
    }

    svg {
      padding: 5px;
      font-size: 20px;
      &:hover {
        cursor: pointer;
        color: $contrast-text-color;
      }
    }
  }

  &-slider {
    width: 100%;

    img {
      width: 100%;
    }
  }
}


@media (max-width: $media-mobile) {
  .mobile-diplay {
    display: inline-block;
  }

  .modal {
    &-dialog-st {
      width: 90%;
      height: 50%;
    }

    &-slider {
      height: 400px!important;

      img {
        height: 350px!important;
      }
    }
  }



  .col {
    &-md {
      &-6 {
        flex: 0 0 100%;
      }
    }
  }
}

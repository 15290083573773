@import 'variables';

.details {
  &-wrapper {
    display: flex;
    max-width: 1240px;
    flex-direction: column;
    width: 100%;
    margin:  auto auto 0;
    padding: 20px;
  }

  &-first {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &-center {
    flex: 0 1 calc(100% - 300px);
    padding-left: 20px;
  }

  &-info {
    padding: 5px 0;

    .image {
      width: 100%;
      height: 500px;

      img {
        width: 100%;
        height: 450px;
      }
    }
  }

  &-header {
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    .name {
      margin-bottom: 10px;
      font-size: 1.37rem;
      font-weight: 500;
    }
  }
  
  &-title {
    font-weight: 500;
    font-size: 1.25rem;
    margin: 20px 0 10px;;
  }

  &-shadow {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    border-radius: $border-radius;
    border: 1px solid $lightest-text-color;
    margin-bottom: 15px;
  }

  &-summary {
    padding: 10px 15px;
  }

  &-sidebar {
    flex: 0 0 320px;
    display: flex;
    flex-direction: column;
    &-header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      border-bottom: 1px solid $lightest-text-color;
      margin-bottom: 10px;
      .name {
        font-weight: 500;
        font-size: 1.25rem;;
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      &-item {
        margin-bottom: 10px;
        svg {
          margin-left: 10px;
          width: 20px;
        }
      }
    }
  }

  &-room {
    &-header {
      background-color: $lightest-text-color;
      font-weight: 500;
      .details-room-col {
        padding: 10px 15px;
      }
      &:nth-child(2) {
        align-items: flex-start;
      }
    }

    &-block {
      &:not(:last-child) {
        border-bottom: 10px solid $lightest-text-color;
      }
    }

    &-row {
      display: flex;
      justify-content: space-between;
      &:last-child {
        padding-bottom: 10px;
      }
      &:not(:last-child) {
        border-bottom: 1px solid $lightest-text-color;
      }
    }

    &-col {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 0 0 20%;
      padding: 5px 15px;
      border-right: 1px solid $lightest-text-color;
      &:first-child {
        align-items: flex-start;
      }
    }

    &-name {
      flex: 0 0 40%;
      svg {
        margin-left: 10px;
        width: 20px;
      }

      span {
        margin-bottom: 5px;
      }
    }

    &-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 15px;
      .price {
        font-size: 1.25rem;
        font-weight: 500;
        margin-left: 30px;
        .title {
          font-size: 1rem;
        }
      }
    }
  }

  &-password {
    padding: 40px;
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
    
    .form-wrapper {
      overflow: hidden;
      padding-bottom: 20px;
      .form-header {
        width: 100%;
        padding: 10px 15px;
        background-color: $lightest-text-color;
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: $media-mobile) {
  .details {
    &-wrapper {
      padding: 15px;
    }
    &-sidebar {
      flex: 0 0 auto;
    }
    &-first {
      flex-direction: column;
    }

    &-center, &-sidebar {
      width: 100%;
      padding-left: 0;
    }


    &-info {
      .image {
        height: 300px;

        img {
          height: 250px;
        }
      }
    }

    &-room {
      &-header {
        display: none;
      }

      &-col {
        border-right: none;
      }

      &-row {
        flex-wrap: wrap;
        padding: 5px 0 10px;
      }

      &-name {
        flex: 0 0 100%;
      }

      &-price {
        flex: 0 0 58%;
        align-items: flex-end;
        margin-right: auto;
        padding: 0 15px;
        margin-top: -20px;

        &-val {
          font-size: 1.13rem;
        }
      }
    }



    &-password {
      padding: 20px 10px;

      button {
        width: 100%;
      }
    }
  }
}